@import "//netdna.bootstrapcdn.com/font-awesome/3.0/css/font-awesome.css";
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap');

* {
    font-family: Ubuntu;
}


.logoDiv {
    text-align: center !important;
    background: transparent;
    position: absolute;
    width: 100%;
    top: 1rem;
}

.logoStyle {
    width: 14rem;
    margin: auto;
}


.navStyle {
    background: transparent;
    width: 100%;
    position: absolute !important;
    top: 5rem;
}

.navC {
    width: 100% !important;
}

.navF {
    color: #ffffffb0 !important;
    width: 15%;
    margin-left: 20rem;
    font-weight: 600;
}

.navL {
    color: #ffffffb0 !important;
    width: 15%;
    font-weight: 600;
}

.headerStyle {
    width: 100%;

    /* background: linear-gradient(90deg, #f2f7f7,#68d1ef ); */

}

.headerImg {

    width: 100%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.title {

    font-size: 3.6vw;

    font-weight: bolder;

    margin-top: 10%;
    margin-bottom: 40px;
    color: #fff;
    text-align: left;
    padding-left: 80px;
}

.subTitle {
    font-size: 1.7vw;
    /* margin-bottom: 28px; */
    font-weight: 500 !important;
    color: #fff;
    text-align: left;
    padding-left: 80px;
}

.cardInfo {
    background-color: #d4e8f9;
    border-radius: 5;
    padding: 75px 50px;
    margin: 0px 25px;
    width: 200px;
    box-shadow: 20px 20px 20px black;
    display: flex;
    justify-content: center;
}

.carsulDiv {

    text-align: center;
    padding: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.crslImg {
    width: 40%;
    display: block;
    text-align: right;
}

.crslCaption {
    text-align: left;
}




.subHeader {
    font-size: 40px !important;
    font-weight: 800 !important;
    color: #01264a;
    margin-bottom: 50px;
}

.cardTxt:last-child {
    font-size: 25px !important;
    font-weight: 500;
    color: #01264a;
    text-align: left;


}

.cardStyle {
    background-color: #ffffff97 !important;
    border-radius: 20px !important;
    border-color: #ffffff97 !important;
    width: 70%;
    text-align: center;
    margin: auto;

}

.productContainer {

    border-radius: 20px;
    text-align: center;
}

.about{
    margin-top: 10rem;
    width:80% !important;
}


.productPage {
    margin: 0px 70px !important;
    padding: 20px 30px;
    border-radius: 20px;
    text-align: center;
}



.productCard {
    margin-right: 20px;
    border-radius: 20px !important;
    margin-bottom: 20px !important;
    max-width: 35vw;
    border: 0 !important;
}

.productImg {
    height: 400px;
}

.showBtn {
    padding: 15px 60px !important;
    margin-top: 20px !important;
    font-size: 16px !important;
    color: white !important;
    background-color: #01264a !important;
    border-radius: 20px !important;
    border-color: #01264a !important;
}

.showBtn:hover {
    background-color: #d5cece !important;
    border-color: #d5cece !important;
    color: #01264a !important;
}

.aboutImg {

    width: 100%
}

.footer {
   background:linear-gradient(180deg , white ,  #74bffc);
    padding: 100px 30px;
    text-align: center;
}

.contactH {
    font-size: 40px !important;
    color: #01264a;
    margin-bottom: 20px;
    margin-top: 15px;

}

.contactRow {
    margin: 20px 100px !important;
}

.contactP {
    font-size: 22px;
    color: #01264a;
    text-align: left;
    text-decoration: none;
}


@media only screen and (max-width: 650px) {

    .navF {
        margin: auto;
        background-color: #d4e8f9e2;
        width: 100%;
        color: #01264a !important;
        padding-left: 60px !important;
    }

    .navL {
        margin: auto;
        background-color: #d4e8f9e2;
        width: 100%;
        color: #01264a !important;
        padding-left: 60px !important;
    }

    .title {
        font-size: 40px;
        padding-left: 0px;
  
    }

    .subTitle {
        font-size: 15px;
        padding-left: 0px;
    }


    .productContainer {
        margin: 10px !important;
        padding: 0px;

    }


    .productCard {
        margin-right: 0px;
        border-radius: 20px !important;
        margin-bottom: 20px !important;
        max-width: 75vw;
    }

    .showBtn {
        padding: 5px 10px !important;
        margin-top: 10px !important;
        font-size: 16px !important;

    }

    .subHeader {
        margin-top: 50px;
    }

    .productImg {
        height: 200px;
    }

    .cardStyle {
        width: 100%;
        margin-left: 5px;
    }

    .cardTxt:last-child {
        font-size: 20px !important;
        font-weight: 400;
        text-align: left;
        margin-left: 0px !important;
    }

    .footer {

        padding: 5px;
        text-align: left;
    }

    .contactRow {
        margin: 20px 10px !important;
    }


    .contactP {
        font-size: 16px;

    }
}